<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultHeader2 :routeBack="'/services'" :titleHeader="'Actualizar servicio'" />
            <hr>
            <!-- section-anexos -->
            <section class="sec-anexos" id="sec-anexos">
                <div class="header-sec-anexos">
                    <h1 class="title-section-anexos">Subir archivos</h1>
                    <i class="fa-solid fa-circle-info"></i>
                </div>
                <div class="content-form">
                    <div class="content-inputs" id="content-inputs">
                        <label class="label" id="label" for="name">Nombre *</label>
                        <input id="name" v-model="dataService.name" class="input input-anexo" name="name" type="text"
                            placeholder="Nombre" required>
                    </div>

                    <div class="content-inputs" id="content-inputs">
                        <label class="label" id="label" for="date">Fecha de publicación *</label>
                        <input id="date" v-model="dataService.date" class="input input-anexo" name="date" type="date"
                            placeholder="Feca de publicación" required>
                    </div>
                </div>
            </section>

            <div>
                <h5>¿Desea cambiar el documento asociado</h5>
                <div>
                    <div class="roundedTwo">
                        <label>
                            <input type="radio" name="opcion" value="si" @change="selectedOption = 'si'"> Sí
                        </label>

                        <label>
                            <input type="radio" name="opcion" value="no" @change="selectedOption = 'no'"> No
                        </label>
                    </div>
                </div>

                <div class="divselect" v-if="selectedOption === 'si'">
                    <div class="div-select-custom">
                        <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()" name="file-5"
                            class="inputfile inputfile-5 input-anexo" required />
                        <label id="label" for="file-5" class="label-file label">
                            <figure>
                                <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20" height="17"
                                    viewBox="0 0 20 17">
                                    <path
                                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                    </path>
                                </svg>
                            </figure>
                        </label>
                    </div>
                    <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar nuevo archivo *</h6>
                </div>
            </div>

            <hr>
            <button class="btn-save-data" @click="updateService()">actualizar
                Archivo</button>
        </div>
    </div>
</template>

<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault
    },
    data() {
        return {
            isLoading: false,
            selectedOption: 'no',
            idParam: '',
            type_service: '',
            selectedFile: File,

            dataService: {
                name: '',
                date: '',
                urlPdf: '',
            },
        }
    },
    mounted() {
        this.idParam = "" + this.$route.params._id
        this.type_service = "" + this.$route.params._type_service
        this.getInfoService()
    },
    methods: {
        async getInfoService() {
            this.isLoading = true
            await db.collection('services')
                .doc(this.type_service)
                .get().then((res) => {
                    const filteredObject = res.data().services.filter((item) => item._idName === this.idParam);
                    this.dataService = filteredObject[0]
                    this.isLoading = false
                })
        },

        async updateService() {
            this.isLoading = true
            if (this.selectedOption === 'si') {
                if (this.selectedFile instanceof File && this.selectedFile.size > 0) {
                    try {
                        await this.deletePdf(this.type_service, this.idParam)
                        const storageRef = storage.ref()
                        const pdfNewRef = storageRef
                            .child('pdfsServices')
                            .child(this.type_service)
                            .child(this.dataService.name);
                        await pdfNewRef.put(this.selectedFile);
                        const pdfUrl = await pdfNewRef.getDownloadURL();
                        this.dataService._idName = this.dataService.name
                        const docRef = db.collection('services').doc(this.type_service);
                        const docSnapshot = await docRef.get();
                        const servicesArray = docSnapshot.data().services;

                        const matchedObject = servicesArray.find((item) => item._idName === this.idParam);

                        if (matchedObject) {
                            matchedObject._idName = this.dataService.name;
                            matchedObject.name = this.dataService.name;
                            matchedObject.date = this.dataService.date;
                            matchedObject.urlPdf = pdfUrl;

                            await docRef.update({ services: servicesArray });
                            this.$swal
                                .fire({
                                    title: 'Servicio actualizado',
                                    text: 'Se ha acutualizado correctamente el servicio',
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonText: 'Aceptar',
                                    confirmButtonColor: "#45A825",
                                })
                            this.isLoading = false
                            this.routerBack()
                        }
                    } catch (error) {                        
                    }
                } else {
                    this.$swal
                        .fire({
                            title: 'Información importante',
                            text: 'Por favor seleccione un archivo para poder actualizar el servici' +
                                'agregar minimo un archivo',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                        })
                    this.isLoading = false
                }
            } else {
                const docRef = db.collection('services').doc(this.type_service);
                const docSnapshot = await docRef.get();
                const servicesArray = docSnapshot.data().services;
                const matchedObject = servicesArray.find((item) => item._idName === this.idParam);
                if (matchedObject) {
                    matchedObject.name = this.dataService.name;
                    matchedObject.date = this.dataService.date;
                    await docRef.update({ services: servicesArray });
                    this.$swal
                        .fire({
                            title: 'Servicio actualizado',
                            text: 'Se ha acutualizado correctamente el servicio',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                            timer: 5000
                        })
                    this.routerBack()
                }
            }

        },

        routerBack() { this.$router.push('/Services') },

        async deletePdf(childOne, childTwo) {
            const storageRef = storage.ref()
            const pdfRef = storageRef.child('pdfsServices')
                .child(childOne)
                .child(childTwo)
            try {
                await pdfRef.delete()
                ('Archivo eliminado con éxito')
            } catch (error) {                
            }
        },

        loadFilePdf() {
            const input = document.getElementById('file-5')
            input.accept = "application/pdf";
            input?.addEventListener('change', (e) => {
                const target = e.target
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                }
            });
        }
    },
}
</script>